<script lang="ts" setup>
import {useHydration} from '@/helpers/useHydration'
import {DetailResponse} from "@eli5/js-helpers";
import {PageResource} from "~/resources/PageResource";
import {notifySentry} from '@/helpers/notifySentry'

useHydration()

const {locale} = useI18n()
const runtimeConfig = useRuntimeConfig()

const i18nHead = useLocaleHead({
  addDirAttribute: true,
  addSeoAttributes: true
})
const Resource = DetailResponse.extend({
  data: PageResource,
})
const {data} = await useFetch(`/pages/${locale.value}/footer`, {
  method: 'GET',
  baseURL: runtimeConfig.public.apiBase,
  transform: (data) => {
    try {
      return Resource.parse(data).data
    } catch (e) {
      notifySentry(e)
    }
  },
})
</script>

<template>
  <div id="default-layout">
    <Html :lang="i18nHead.htmlAttrs?.lang" :dir="i18nHead.htmlAttrs?.dir"></Html>
    <Head>
      <template v-for="link in i18nHead.link" :key="link.id">
        <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang"/>
      </template>
      <template v-for="meta in i18nHead.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content"/>
      </template>
    </Head>
    <DefaultNavigation/>
    <main>
      <slot></slot>
    </main>
    <DefaultFooter v-if="data" :content="data.footer" />
  </div>
</template>

<style lang="postcss" scoped>
#default-layout {
  display: grid;
  min-height: 100dvh;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: min-content 1fr min-content;
}
</style>
